<template>
  <div class="container">
    <div class="legal-container">
      <h2 class="heading-secondary">Qtrade Summer Showdown Rules</h2>
      <h3 class="heading-tertiary">ELIGIBILITY</h3>
      <p class="legal">
       The following individuals and their immediate family are prohibited from 
    participating in the Qtrade Summer Showdown: Verdant Analysis, Inc. employees 
    and board members. Company Advisors, Partners and anyone with a material 
    financial interest in the company are also prohibited from participating.
    </p>
    <p class="legal">
    Participants must be a Canadian resident who is of the age of majority in their 
    respective province or territory.
    </p>
    <p class="legal">
    No purchase is necessary to join the competition.
      </p>
    <p class="legal">To qualify for the competition prizes, participants must:</p>
      <ul style="margin-left:3.8rem;">
        <li class="legal">
          Have an INVRS account,
        </li>
        <li class="legal">
          Complete in-site registration process by July 31, 2024,
        </li>
        <li class="legal">
          Be active and trade within the competition timeframe of July 22, 2024, to 
August 30, 2024
        </li>
      </ul>
      <h3 class="heading-tertiary">PRIZES</h3>
      <p class="legal">
        <table>
          <th>Award</th><th>Prize Money</th>
          <tr><td>Highest Return</td><td>	$1000</td></tr>
          <tr><td>Second Highest Return</td><td>	$250</td></tr>
          <tr><td>Third Highest Return</td><td>	$100</td></tr>
          <tr><td>Top 10 (Positions 4 to 10)</td><td> $50 </td></tr>
        </table>
        <br>
      </p>
      <h3 class="heading-tertiary">COMPETITION STRUCTURE</h3>
      <p class="legal">
        Competition participants will be given 100,000 of in-app USD currency to trade securities during the competition with your ranking dictated on the financial return on the securities you transact with a higher return corresponding to a higher ranking. 
      </p>
      <p class="legal">
      Participants can make as many trades as they like.  Trades are executed between Monday to Friday and the hours of 9:30 a.m. to 4:00 p.m Eastern Standard Time.   Trades made outside of market hours are queued for execution during market hours.  
      </p>
      <p class="legal">
      The competition runs from July 22, 2024, to August 30, 2024.   Trades made before and after the competition timeframe will not be executed.  You do not have to exit positions at the end of the competition.
      </p>
      <p class="legal">
      Short selling of securities trading at less than $3/share will not be permitted, and the filling of orders will be subject to a random delay of up to 15 minutes to discourage front running. 
      </p>
       <h3 class="heading-tertiary">GUIDANCE ON TRADING</h3>
      <p class="legal">
        <strong>Make Informed Decisions.</strong> By utilizing INVRS’s research and education material you can learn the basics and reinforce existing learnings. 
      </p>
      <p class="legal">
      <strong>Ask Questions.</strong> If you are unsure, always feel free to post a question onto INVRS. The platform is filled with knowledgeable and experienced investors who would be happy to answer any questions that you may have. 
      </p>
      <p class="legal">
      <strong>Be Active.</strong> As we all know the market can change quickly. By keeping active on financial news and stock price changes you will have an edge over your competition
      </p>
      <h3 class="heading-tertiary">PRIZE MONEY</h3>
      <p class="legal">
        Prizes will be awarded after the competition closes on August 30, 2024. Interact e-transfer is the only allowable payment method to receive prize winnings. 
        </p>
      <p class="legal">
        <strong>Tie:</strong> In the unusual case of a tie – the placement prize money will be divided equally between the two users.
      </p>
      <h3 class="heading-tertiary">RESTRICTIONS ON MULTIPLE ENTRIES</h3>
      <p class="legal">
        If a winning user controls multiple accounts placing in the top 10, the highest-ranking account will be awarded prize money, but the remaining accounts will be voided in the rankings and next ranked account will receive their prize money.
      </p>
      <h3 class="heading-tertiary">PRIZE WINNER NOTIFICATION AND DELIVERY OF PRIZE</h3>
      <p class="legal">
        Winners will be notified by email.  Please confirm your email address when you sign up to ensure we can get the prize to you.  Once notified, we arrange delivery via Interact e-transfer to receive your winnings.  The email used to send the funds is the one you used to register with INVRS.  A false or incorrect email voids your award. 
      </p>
       <h3 class="heading-tertiary">UNCLAIMED AWARDS</h3>
      <p class="legal">
        Verdant Analysis, Inc. bears no responsibility for distributing awards that are not claimed within one month of announcement.  If an award is not claimed, we will announce the next highest ranked participant as the unclaimed winner.
      </p>
      <h3 class="heading-tertiary">CHANCE OF WINNING</h3>
      <p class="legal">
        This is a skills-based contest, not a game of chance.  The more work you put into your trades, the greater your chance of winning.  That said, the number of participants will impact your chances of winning.  
      </p>
       <h3 class="heading-tertiary">NUMBER OF PARTICIPANTS</h3>
      <p class="legal">
        INVRS reserves the right to cap the number of participants at any time.
      </p>
       <h3 class="heading-tertiary">CURRENCY OF PRIZE MONEY</h3>
      <p class="legal">
        All prize money will be awarded in Canadian dollars via Interact e-transfer sent to the email you used to register with INVRS.  No exceptions. 
      </p>
      <h3 class="heading-tertiary">PERSONAL IDENTIFIABLE INFORMATION</h3>
      <p class="legal">
        INVRS only collects information necessary to manage and market the competition.  All collection policies are clearly outlined in the <router-link class="footer-link" :to="{ name: 'privacy' }">INVRS Privacy Policy. </router-link>
      </p>
      <h3 class="heading-tertiary">RIGHT TO MODIFY, SUSPEND, LIMIT OR REVOKE</h3>
      <p class="legal">
        INVRS reserves the right, at its sole and absolute discretion, to modify, suspend, disqualify, limit or revoke this competition and/or any prize money payable to any person or account holder for any reason, including but not limited to manipulation or abuse of the process, fairness, integrity or operation of the competition.
      </p>
       <h3 class="heading-tertiary">FAQ</h3>
      <p class="legal">
        <strong>Is there a minimum number of trades?</strong><br>
        There are no minimum or maximum number of trades.
      </p>
      <p class="legal">
        <strong>Are there any trading or usage restrictions?</strong><br>
        The INVRS free tier permits three trades per day and five unique research sessions every 30 days.  An investor pass removes those limits and can be purchased with Playdough.
      </p>
      <p class="legal">
        <strong>Can I put in a trade when the market is closed?</strong><br>
        Yes, the order will be filled when the market is next opened, subject to the random delay.
      </p>
    </div>
  </div>
</template>

<style scoped>


table {
  border-spacing: 0px;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  background-color: transparent; /* Change the background-color of table here */
  text-align: left; /* Change the text-alignment of table here */
}

th {
  font-weight: bold;
  border: 1px solid #cccccc; /* Change the border-color of heading here */
  padding: 8px;
}

td {
  border: 1px solid #cccccc; /* Change the border-color of cells here */
  padding: 8px;
}

.legal {
  margin-bottom: 3.2rem;
  line-height: 1.3;
}

.legal-container {
  padding: 14.4rem 12rem;
}

.heading-tertiary {
  margin-bottom: 1.6rem;
  line-height: 1.2;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .legal-container {
    padding: 14.4rem 6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .legal-container {
    padding: 12.8rem 2.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .legal-container {
    padding: 12.8rem 1.2rem;
  }
}
</style>
